* {
  box-sizing: border-box;
  font-family: "Inconsolata";
}
.App {
  background-image: linear-gradient(to right, rgb(53, 53, 53), rgb(93, 93, 93));
  box-shadow: inset 0 0 50px rgb(104, 104, 104);
}

#navbar {
  background-color: #17252a;
  position: fixed;
  top: -0;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

#navbar a {
  display: inline-block;
  color: #def2f1;
  align-self: start;
  text-align: center;
  padding: 10px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.1em;
}

#navbar a:hover {
  background-color: #def2f1;
  color: #17252a;
}

#social-menu {
  position: fixed;
  right: 0;
  bottom: 0;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-right: 20px;
  z-index: 2;
}
ul#social-menu {
  list-style: none;
}
svg {
  color: #cdc013;
  margin-top: 10px;
  border-radius: 5px;
}
svg:hover {
  color: #cbbe1076;
}

#about {
  min-height: 800px;
  display: flex;
  background-image: url("images/background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #cdc013;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #def2f1;
  text-align: justify;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  -webkit-animation: about-image 3s; /* Chrome, Safari, Opera */
  animation: about-image 3s;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes about-image {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}

/* Standard syntax */
@keyframes about-image {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}

#about h1 {
  font-size: 6rem;
  font-weight: bold !important;
  margin-top: 50px;
  margin-bottom: 0px;
  text-align: center;
  color: #17252a;
  text-shadow: 1px 1px 3px #cdc013;
}
.last-name {
  display: inline-block;
}
#about h2 {
  font-size: 2rem;
  font-weight: bold !important;
  text-align: center;
  color: #17252a;
  text-shadow: 1px 1px 3px #cdc013;
}
.about {
  width: 60%;
  float: center;
  background-color: #17252a;
  margin-bottom: 100px;
}
.about-text {
  width: 100%;
  padding: 50px;

  margin: 0 auto;
  line-height: 2rem;
  font-size: 1.15rem;
}
.profile-pic {
  margin-top: 10px;
  padding: 50px 20px 50px 10px;
  text-align: left;
  width: 250px;
}

#projects {
  line-height: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-bottom: 20px;
  padding-top: 50px;
  color: #c9c9c9;
}
#contact h2,
#resume h2,
#skills h2,
#projects h2 {
  font-weight: bold;
  margin: 0 auto;
  font-size: 2.75rem;
  margin-bottom: 0px;
  color: rgb(179, 178, 178);
}
.project-div:first-child {
  border-bottom: 1px dashed #c9c9c9;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-top: 30px;
}
.project-div {
  border-bottom: 1px dashed #c9c9c9;
  padding-top: 30px;
}
.project-div:last-child {
  border-bottom: none;
  margin-top: 30px;
  margin-bottom: 30px;
}

h3.title {
  margin: 5px 0 5px;
  font-size: 1.75rem;
  color: white;
}

.video {
  position: relative;
  height: 0;
  padding-top: 25;
  padding-bottom: 56.25%;
  align-content: center;
  align-self: center;
  margin: 0 auto;
  max-width: 688px;
}
iframe {
  position: absolute;
  top: 0;
  left: 0;
  align-self: center;
  width: 100%;
  height: 100%;
}
.github-live {
  display: inline-flex;
  margin: 0 auto;
}

.github,
.github:visited {
  cursor: pointer;
  padding-top: 0;
  margin-top: 0;
  margin: 0 auto;
  color: #cdc013;
  text-align: center;
  font-weight: 700;
}
.live,
.live:visited {
  cursor: pointer;
  color: #cdc013;
  padding-top: 0;
  margin-top: 0;
  margin-left: 5px;
  text-align: center;
  font-weight: 700;
}
.github:hover,
.live:hover {
  color: #cbbe1076;
}

#projects li {
  line-height: 1.25rem;
  text-align: left;
  width: 500px;
  margin: 0 auto;
  padding-top: -15px;
  color: white;
  margin-bottom: 10px;
  margin-right: 20px;
  text-align: justify;
}
#projects li:last-child {
  margin-bottom: 30px;
}

#skills {
  line-height: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #def2f1;
  text-align: center;
  padding-top: 50px;
}

.skills {
  padding-top: 0px;
  padding-left: 5px;
  line-height: 1.15em;
  font-weight: bold;
  font-size: 1.75em;
  max-width: 60%;
  overflow: auto;
}
.skills-item {
  margin-bottom: -10px;
  float: left;
  padding: 5px;
  margin: 0 auto;
  list-style: square inside;
}
#resume {
  line-height: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #def2f1;
  text-align: center;
  padding-top: 50px;
  width: 70%;
  border-top: 1px dashed grey;
  margin: 0 auto;
}
.download {
  display: block;
  margin: 0 auto;
  width: 50%;
}
a.download {
  color: #17252a;
  background-color: #cdc013;
  text-decoration: none;
  text-align: center;
  font-size: 1.2em;
  margin-top: 30px;
}
a.download:hover {
  background-color: #cbbe1076;
}
.resume {
  margin: 0 auto;
  text-align: justify;
  width: 70%;
  margin-bottom: 30px;
  background-color: white;
  color: #17252a;
  padding-left: 30px;
  padding-right: 30px;
}

.roles-experience {
  line-height: 1.15em;
  display: block;
  padding-bottom: 3px;
  text-decoration: none;
  padding-right: 25px;
}

.roles-experience span {
  font-weight: bold;
  display: block;
  font-size: 1.1em;
  color: rgb(124, 124, 124);
  text-decoration: underline;
}
.role-experience-title {
  display: block;
  font-size: 1.05em;
  color: #2f2f2f;
  font-weight: bold;
}
.roles-experience-about {
  display: block;
  line-height: 1.4em;
  text-align: justify;
  font-weight: normal;
  font-size: 0.9rem;
}
span.title {
  font: inherit;
  color: rgb(78, 75, 56);
  font-size: 1.1rem;
  line-height: 1.3em;
  padding: 0;
  font-weight: bold;
  text-decoration: none;
  margin-left: 25px;
}
span.description {
  font-size: 0.9em;
  padding: 0;
  line-height: 1.4em;
  color: #2f2f2f;
  text-decoration: none;
  margin-left: 40px;
  font-weight: bolder;
}

#contact {
  line-height: 1.15em;
  display: flex;
  align-items: center;
  color: #17252a;
  text-align: center;
  margin-top: 50px;
}

.contact {
  margin: 0 auto;
  text-align: left;
  width: 50%;
  padding-left: 30px;
}
.contact-details {
  line-height: 1.45rem;
  padding-top: 30px;
  padding-bottom: 50px;
}
.details {
  font-size: 1.25rem;
  color: white;
}

#contact a {
  color: #cdc013;
  text-decoration: none;
  cursor: pointer;
  word-break: break-word;
}
#contact a:hover {
  color: #cbbe1076;
}

@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  .App {
    width: 100%;
  }

  #navbar {
    font-size: 0.75rem;
  }
  #navbar a {
    padding-right: 5px;
  }
  .about {
    display: block;
    width: 90%;
    text-align: center;
  }
  .about-text {
    width: 100%;
    background-color: #17252a;
    padding: 20px;
    margin: 0 auto;
    line-height: 1.5rem;
    text-align: justify;
  }
  .profile-pic {
    width: 80%;
    max-width: 300px;
    margin: 0 auto;
  }
  .video {
    position: relative;
    height: 0;
    padding-top: 25;
    padding-bottom: 56.25%;
    align-content: center;
    align-self: center;
    margin: 0 auto;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    align-self: center;
    width: 100%;
    height: 100%;
  }

  #about h1 {
    padding-top: 100px;
    font-size: 3rem;
    font-weight: bold;
  }

  #about h2 {
    font-size: 1.5rem;
    font-weight: bold;
  }
  #contact h2 {
    line-height: 2.25rem;
  }

  #projects li {
    line-height: 1.25rem;
    text-align: left;
    margin: auto;
    padding-top: -15px;
    width: 80%;
  }
  #resume {
    width: 90%;
  }
  .download {
    width: 70%;
  }
  .resume {
    width: 100%;
  }
  .contact {
    width: 70%;
  }
  .skills {
    font-size: 100%;
  }
}
